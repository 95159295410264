import React, { useState, useRef } from 'react'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useUploadProfileImageFormDataMutation, useUploadProfileImageMutation } from '../../redux/appSlices/collaborator/collaboratorApiSlice';
import { useSelector } from 'react-redux';
import { translation } from '../../translation';
import { toast } from 'react-toastify';

const CropProfilePic = ({ setOpenEditPopup, imageSrc, setOpenPopup, setProfileLoading }) => {
  const cropperRef = useRef();
  const [imageElement, setImageElement] = useState();

  const lang = useSelector((state) => state.collaborator.collaboratorInfo.language)

  const [uploadProfileImage] = useUploadProfileImageMutation();
  const [uploadProfileImageFormData] = useUploadProfileImageFormDataMutation();

  const onCrop = () => {
    setImageElement(cropperRef?.current);
  };

  const onSubmit = () => {
    const cropper = imageElement?.cropper;
    const data = cropper.getCroppedCanvas().toDataURL();
    // setOpenEditPopup(false)
    // typeof(cropper)
    // console.log('typeof(cropper) =>', typeof(cropper))
    // console.log('data', data)


    const byteString = atob(data.split(',')[1]);
    const mimeString = data.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });

    // Create a File object
    const fileName = 'cropped_image.jpg';
    const file = new File([blob], fileName, { type: "image/jpeg" });
    // console.log('file', file)
    setOpenPopup(false)
    setOpenEditPopup(false)

    uploadProfileImageFormData(file)
      .unwrap()
      .then((res) => {
        setProfileLoading(false)
      })
      .catch((err) => {
        setProfileLoading(false)
        toast.error(translation.cropImage.errorUpload[lang]);
      })


    // uploadProfileImage({ 'base64': data })
    //   .unwrap()
    //   .then((res) => {
    //     setOpenPopup(false)
    //     setOpenEditPopup(false)
    //     setProfileLoading(false)
    //   }).catch((err) => {
    //     setOpenPopup(false)
    //     setOpenEditPopup(false)
    //     setProfileLoading(false)
    //     // console.log('err', err)
    //     toast.error(translation.cropImage.errorUpload[lang]);
    //   })
  };


  return (
    <section className="pt-[7vh] mx-5">
      {imageSrc && (
        <>
          <div className='min-h-[75vh]'>


            <p className='mb-5 pl-[4vh] text-[18px]'>{translation.cropImage.title?.[lang]}</p>
            <div className='relative w-full h-[60vh]'>
              <Cropper
                src={URL.createObjectURL(imageSrc)}
                crop={onCrop}
                ref={cropperRef}
                viewMode={1}
                aspectRatio={134 / 168}
              />
            </div>
          </div>
          <div className="button-container">
            <button className='btn-fields-terminer btn-active' onClick={onSubmit}>
              {translation.cropImage.button?.[lang]}
            </button>
          </div>
        </>
      )}
    </section>
  )
}

export default CropProfilePic