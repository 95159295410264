import React, { useEffect, useState } from 'react'

import { About, ProfessionalInfo, Socials, Calendly, Details, Images, Links, ProductServices, Videos } from '../onBoarding'
import OrderSections from './OrderSections'
import CropProfilePic from './CropProfilePic';
import Login from '../../views/auth/Login';
import Setting from './ShowSetting/Setting';
import ForgetPassword from '../../views/ForgetPassword';
import PdfReader from './PdfReader';
import { LuBookOpen } from "react-icons/lu";
import { LuFileSymlink } from "react-icons/lu";
import { useSelector } from 'react-redux';



const EditComponants = ({ componantId, setOpenEditPopup, setProfileLoading, imageSrc, setOpenPopup, pdfPath }) => {

  const lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );

  const [componantIdRender, setComponantIdRender] = useState(componantId)

  // prevent the background from scrolling when the popup is open
  useEffect(() => {
    if (componantId) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [componantId, componantIdRender]);
  // END prevent the background from scrolling when the popup is open

  const componants = [
    <OrderSections setOpenEditPopup={setOpenEditPopup} />,
    <About edit={true} setOpenEditPopup={setOpenEditPopup} />,
    <ProfessionalInfo edit={true} setOpenEditPopup={setOpenEditPopup} />,
    <Socials edit={true} setOpenEditPopup={setOpenEditPopup} />,
    <ProductServices edit={true} setOpenEditPopup={setOpenEditPopup} />,
    <Calendly edit={true} setOpenEditPopup={setOpenEditPopup} />,
    <Links edit={true} setOpenEditPopup={setOpenEditPopup} />,
    <Images edit={true} setOpenEditPopup={setOpenEditPopup} />,
    <Videos edit={true} setOpenEditPopup={setOpenEditPopup} />,
    <Details edit={true} setOpenEditPopup={setOpenEditPopup} />,
    <Setting edit={true} setOpenEditPopup={setOpenEditPopup} />,
    <CropProfilePic setOpenEditPopup={setOpenEditPopup} setProfileLoading={setProfileLoading} imageSrc={imageSrc} setOpenPopup={setOpenPopup} />,
    <Login edit={true} setOpenEditPopup={setOpenEditPopup} setComponantIdRender={setComponantIdRender} />,
    <ForgetPassword setOpenEditPopup={setOpenEditPopup} setComponantIdRender={setComponantIdRender} />,
    <PdfReader pdfPath={pdfPath} />
  ]
  return (
    <div className=" bg-popcard z-[99999] fixed animate-slide inset-0 min-h-[100vh] overflow-x-auto pb-80">
      <div className="max-w-xl m-auto">
        {componantIdRender === 14
          ? <div className='cursor-pointer flex justify-between mt-9 mx-5'>
            <a
              href={`${process.env.REACT_APP_PUBLIC_API}/pdfs/${pdfPath}`}
              className="text-black flex w-full"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LuFileSymlink size={20}/>
            </a>
            <div className='cursor-pointer' onClick={() => setOpenEditPopup(false)}>
              <img src="../../assets/img/icons/close.svg" alt="close popup" />
            </div>
          </div>
          : <div className={`cursor-pointer flex justify-end mt-9 ${lang === 'ar' ? 'ml-5' : 'mr-5'}`} onClick={() => setOpenEditPopup(false)}>
            <img src="../../assets/img/icons/close.svg" alt="close popup" />
          </div>}
        {componants[componantIdRender]}
      </div>
    </div>
  )
}

export default EditComponants