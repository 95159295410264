import React, { useEffect, useState } from "react";
// import { useGlobalState } from "..";
// import Close from "../componants/Close";
// import AppServices from "../services/AppServices";
import { useSelector } from "react-redux";
import { translation } from "../translation";
import { usePostForgotPasswordMutation } from "../redux/appSlices/collaborator/collaboratorApiSlice";
import EditComponants from "../componants/profile/EditComponants";

const ForgetPassword = ({ setOpenEditPopup, setComponantIdRender }) => {
  const [editComponantId, seteditComponantId] = useState(0);
  // const [editComponantId, seteditComponantId] =
  //   useGlobalState("editComponantId");
  // const [trans] = useGlobalState("onboarding_trans");
  // const [branding] = useGlobalState("branding");
  const branding = null

  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );
  let lang = useSelector(
    (state) => state.collaborator.collaboratorInfo.language
  );
  if (!lang) {
    lang = "fr";
  }


  const [email, setEmail] = useState("");
  const [safe, setSafe] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(2);
  const [messages, setMessages] = useState([
    `✅ ${translation?.["edit-information-login"]?.topr?.[lang]} « ${email} »`,
    `🚫 « ${email} » ${translation?.["edit-information-login"]?.ressaye?.[lang]}`,
    translation?.["edit-information-login"].subtitle[lang],
  ]);

  const [postForgotPassword] = usePostForgotPasswordMutation()

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    if (email.length > 0 && validateEmail(email)) {
      setSafe(true);
    } else {
      setSafe(false);
    }
    setMessages([
      `✅ ${translation?.["edit-information-login"]?.topr?.[lang]} « ${email} »`,
      `🚫 « ${email} » ${translation?.["edit-information-login"]?.ressaye?.[lang]}`,
      translation?.["edit-information-login"]?.subtitle?.[lang],
    ]);
  }, [email, lang]);

  useEffect(() => {
    if (currentMessage == 1) {
      setTimeout(() => {
        setCurrentMessage(2);
      }, 3000);
    }
  }, [currentMessage]);

  const handleEmail = () => {
    // AppServices.post(
    //   {
    //     action: 1,
    //     data: {
    //       email: email,
    //       logo: branding?.logo ? branding?.logo : undefined,
    //       mailto: branding?.mailto ? branding?.mailto : undefined,
    //       domain: branding?.domain ? branding?.domain : undefined,
    //       server: branding?.server ? branding.server : undefined,
    //     },
    //   },
    //   "/email"
    // )
    //   .then((respose) => {
    //     setCurrentMessage(0);
    //   })
    //   .catch(() => {
    //     setCurrentMessage(1);
    //   })
    postForgotPassword({
      email: email
    }).unwrap().then((res) => {
      setCurrentMessage(0);
    }).catch((err) => {
      setCurrentMessage(1);
      console.log(err);
    })
  };

  return (
    <>
      {/* <Close /> */}
      <section className="login-content z-[9999]">
        <div className="avatar-container">
          <div class="avatar-container">
            <img
              src={
                collaborator.profile_picture
                  ? `${process.env.REACT_APP_IMAGE_PATH}` + collaborator.profile_picture
                  : "/assets/img/logo-black.svg"
              }
              alt=""
              style={!collaborator.profile_picture ? { padding: "10px", objectFit: "contain" } : { objectFit: "cover" }}
              srcset=""
            />
          </div>
        </div>
        <div className={`avatar-title ${lang === 'ar' ? '!font-readexRegular' : ''}`}>
          {collaborator.first_name
            ? `${translation?.["edit-information-login"]?.bonjour?.[lang]} ${collaborator.first_name} !`
            : translation?.["edit-information-login"]?.["pass-oublie"]?.[lang]}
        </div>
        <div
          className={`
            ${currentMessage === 0
              ? "avatar-description text-success !text-[#198754]"
              : currentMessage === 1
                ? "avatar-description text-dander"
                : "avatar-description"}
            ${lang === 'ar' ? '!font-readexLight' : ''}
          `}
        >
          {messages[currentMessage]}
        </div>
        <div>
          <div className="inputs-container">
            <div className="single-input">
              <label htmlFor="email" className={`label ${lang === 'ar' ? '!font-readexRegular' : ''}`}>
                {translation.loginPage.email[lang]}
              </label>
              <input
                dir='ltr'
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                type="text"
                required=""
                name="email"
                className="login-input"
                id="email-pwd"
              />
            </div>
          </div>
          <div className="flex justify-between items-center gap-[20px] mt-[50px]">
            <div
              className={`btn-back-forgot-pwd cursor-pointer ${lang === 'ar' ? '!font-readexRegular' : ''}`}
              onClick={() => {
                setComponantIdRender(12)
              }}
            >
              {lang === 'ar' ? '→' : '←'}{translation?.["edit-information-login"]?.retour?.[lang]}
            </div>
            <button
              className={`${safe ? "pop-btn-disabled btn-active cursor-pointer" : "pop-btn-disabled"} ${lang === 'ar' ? '!font-readexRegular' : ''}`}
              style={{ marginTop: 0 }}
              id="btn-fp"
              disabled={!safe}
              onClick={handleEmail}
            >
              {translation?.["edit-information-login"]?.buttonSend?.[lang]}
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgetPassword;
