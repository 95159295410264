import React, { useEffect, useState } from "react";
import "../../styles/LanguageSelector.scss";
import EnglishFlag from "../../assets/england.svg";
import FrenchFlag from "../../assets/france.svg";
import { useDispatch } from "react-redux";
import { setLang as setReduxLang } from "../../redux/appSlices/collaborator/collaboratorSlice";

const LanguageSelector = () => {
  const dispatch = useDispatch()
  const [lang, setLang] = useState("fr");
  const [selectedLanguage, setSelectedLanguage] = useState(
    lang === "en" ? "English" : "Francais"
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const options = [
    { value: "fr", label: "Francais", icon: "/assets/img/icons/fr-flag.svg" },
    { value: "en", label: "English", icon: "/assets/img/icons/en-flag.svg" },
    { value: "ar", label: "Arabic", icon: "/assets/img/icons/ar-flag.png" },
  ];

  useEffect(() => {
    dispatch(setReduxLang(lang))
  }, [lang])

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (language, langValue) => {
    setSelectedLanguage(language);
    // if (language === "English") {
    //   setLang("en");
    // } else {
    //   setLang("fr");
    // }
    setLang(langValue)
    localStorage.setItem('lang', langValue)


    setIsDropdownOpen(false);
  };



  return (
    <div className="dropdown-container">
      <div className="custom-dropdown">
        <div
          className="custom-dropdown__selected-item"
          onClick={handleDropdownToggle}
          style={{ backgroundColor: "#FFFAF5" }}
        >
          <img
            src={options.find(option => option.value === lang).icon}
            alt={`${selectedLanguage} Flag`}
            className="custom-dropdown__flag"
          />
          <span className="custom-dropdown__language">{selectedLanguage}</span>
          <span className="custom-dropdown__arrow text-primaryP text-[13px]">
            {isDropdownOpen ? "▲" : "▼"}
          </span>
        </div>
        {isDropdownOpen && (
          <div
            className="custom-dropdown__dropdown"
            style={{ backgroundColor: "#FFF3E7" }}
          >
            {options
              .filter((option) => option.value !== lang)
              .map((opt, i) => (
                <div
                  className={`flex items-center p-[8px] cursor-pointer text-[20px] pt-[18px] ${i === 0 ? 'mt-[40px]' : 'rounded-b-[18px]'} hover:bg-[#FFDBB3]`}
                  onClick={() => handleOptionClick(opt.label, opt.value)}
                >
                  <img
                    src={opt.icon}
                    alt="French Flag"
                    className="h-8 w-8 rounded-[50%] mr-2 bg-cover"
                  />
                  <span className="flex-grow">{opt.label}</span>
                </div>
              ))
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageSelector;
