import React, { useState } from "react";
import StepOne from "./auth/StepOne";
import StepTwo from "./auth/StepTwo";
import StepThree from "./auth/StepThree";
import { useSelector } from "react-redux";
import useCollaboratorRestriction from "../../hooks/useCollaboratorRestriction";
import { translation } from "../../translation";

const Auth = () => {

  const restriction = useCollaboratorRestriction();

  // const authTranslation = useSelector(state=>state.translation.auth)
  const authTranslation = translation.onboarding.auth
  const lang = useSelector(state => state.collaborator.collaboratorInfo.language) ?? 'fr'
  
  const [authStep, setAuthStep] = useState(0);

  const authSteps = [
    <StepOne setAuthStep={setAuthStep} authTranslation={authTranslation} lang={lang} />,
    <StepTwo setAuthStep={setAuthStep} authTranslation={authTranslation} lang={lang} restriction={restriction}/>,
    <StepThree authTranslation={authTranslation} lang={lang} restriction={restriction}/>,
  ];
  return authSteps[authStep];
};

export default Auth;
