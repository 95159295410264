import React from 'react';
import './switchCreateProfile.css'
import { useSelector } from 'react-redux';

const SwitchCreateProfile = ({ isOpen, message = "Création d'un nouveau profil" }) => {
    const lang = useSelector(
        (state) => state.collaborator.collaboratorInfo.language
      );
    
    if (!isOpen) return null;



    return (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-8 shadow-xl animate-slideIn w-full mx-4 max-w-[440px] m-auto">
                <div className="flex flex-col items-center gap-6">
                    <p className={`text-black text-center animate-pulse ${lang === 'ar' ? '!font-readexRegular' : 'font-SteradianRegular'}`}>
                        {message}
                    </p>
                    <div className="w-full h-2 bg-gray-100 rounded-full overflow-hidden">
                        {/* <div className="h-full w-full bg-blue-500 animate-macProgress rounded-full" /> */}
                        <div className="h-full w-full bg-black animate-macProgress rounded-full" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SwitchCreateProfile;