import React from 'react'

const ListProduct = ({ product, lang, setProductIdToEdit, setOpenProductPopup,
  listProduct,
  setListProduct }) => {
  return (
    <div className='relative flex gap-3 mt'>
      <div className='flex-shrink-0 h-[114px] w-[114px]'>
        <img className='object-cover w-full h-full rounded-lg' src={`${process.env.REACT_APP_IMAGE_PATH}${product.pictures[0]}`} />
        {/* <img className='object-cover w-full h-full rounded-lg' src={`https://mongodb.getpopcard.com/images/${product.pictures}`} /> */}
      </div>
      <div className="flex flex-col justify-between w-full">
        <div className="text-[#101010] font-[400] text-[16px]">
          {product?.title?.[lang]}
        </div>
        <div className="text-[#4E3131] font-bold text-[18px]">
          {product?.price?.amount === 0 || !product?.price?.amount
            ? ""
            : product?.price?.amount}{" "}
          <span className="text-[16px] font-normal">
            {product?.price?.amount === 0 || !product?.price?.amount
              ? ""
              : product?.price?.currency}
          </span>
        </div>
        {/* ########################## List of store ########################## */}
        <div className="flex gap-[5px]">
          {product?.links?.map((link) => (
              <a link={
                link?.store?.label === 'Whatsapp'
                  ? `https://wa.me/+${link.url.replace("-", "")}`
                  : (link.url && link.url.startsWith('http') ? link.url : `http://${link?.url}`)
              } target="_blank">
                <img
                  src={`${link?.store?.icon}.png`}
                  className="w-[35px] h-[35px] shadow-sm object-contain rounded-lg"
                />
              </a>
          ))}
        </div>
      </div>
      <div className={`absolute flex gap-2 mt-[-1rem] ${lang === 'ar' ? 'left-0' : 'right-0'}`}>
        <div onClick={() => {
          // console.log("should edit this id", product._id)
          setProductIdToEdit(product._id)
          setOpenProductPopup(true)
        }}>
          <img src="/assets/img/icons/edit-icon.svg" alt="update product or service" />
        </div>

        <div onClick={() => {
          const newProducts = listProduct.filter((item) => item._id !== product._id);
          setListProduct(newProducts);
        }}>
          <img src='/assets/img/icons/delete.svg' alt="Delete product" />
        </div>

      </div>
    </div>
  )
}

export default ListProduct