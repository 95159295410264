import React from "react";
import LottieCard from "../../animation/LottieCard";

const StepTwo = ({ setAuthStep, authTranslation, lang, restriction }) => {
  return (
    <section className="fields-content step2 mx-5">
      <div className={`field-title flex pt-[8vh] text-2xl ${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`}>
        {authTranslation.title2?.[lang]}
        <img
          src={`${
            // branding?.logo ? branding?.logo :
            "../../assets/img/logo-black.svg"
            }`}
          style={{ margin: "0 10px" }}
          alt=""
          srcSet=""
          className="w-[129px] object-contain"
        />
        👋🏻
      </div>
      <div className={`field-description text-lg mt-4 ${lang === 'ar' ? '!font-readexRegular' : '!font-SteradianMedium'}`}>

        {restriction.show_onboarding_name ? (
          <p>
            {`${authTranslation.subtitle2One?.[lang]}`}
            <p>
              {authTranslation?.offered?.[lang]}{" "}
              <span className={`${lang === 'ar' ? 'font-lalezarRegular' : 'font-SteradianMedium'}`}>{restriction.onboarding_name}</span></p>
          </p>
        ) : (
          `${authTranslation.subtitle2One?.[lang]}.`
        )}

        <p className="block mt-9">
          {authTranslation.subtitle2Two?.[lang]}
        </p>
      </div>

      <LottieCard />

      <div className="button-container">
        <button
          className={`btn-welcome ${lang === 'ar' ? '!font-readexRegular' : ''}`}
          onClick={() => {
            setAuthStep(2);
          }}
        >
          {authTranslation.buttonTwo?.[lang]}
        </button>
      </div>
    </section>
  );
};

export default StepTwo;
