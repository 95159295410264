import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useCollaboratorRestriction = () => {
  const collaborator = useSelector(
    (state) => state.collaborator.collaboratorInfo
  );

  if (collaborator.team_id) {
    return collaborator.team_id
  } else {
    return collaborator.backoffice_id
  }
};

export default useCollaboratorRestriction;
